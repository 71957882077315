// Variables
@import "../../../sass/variables";

.dialog-content {
    text-align: left !important;

    .content-left, .content-right {
        padding: 10px 20px;

        .section-title {
            font-size: 1rem;
        }
    }

    .content-left {
        .label-text {
            margin-top: 15px;
            margin-bottom: 0;
        }

        .password-reset {
            color: $theme-accent-black;
            text-decoration: underline;
        }

        .login-check {
            text-align: center;
            margin: 20px 0;

            label {
                margin: 0;
            }
        }
    }

    .content-right {
        border-left: 1px solid $theme-main-lightgray;

        .description {
            margin-top: 15px;
        }
    }
}

@media (min-width: 768px) and (max-width: 1079px) {
    .dialog .dialog-content {
        min-width: 80%;
    }
}

// スマートフォン対応
@media (max-width: 767px) {
    .dialog-content {
        padding-left: 0;

        .page-title {
            width: unset!important;
        }

        .content-left, .content-right {
            padding: 10px;
        }

        .content-left {
            padding-bottom: 30px;
        }

        .content-right {
            padding-top: 30px;
            border-left: none;
            border-top: 1px solid $theme-main-lightgray;
        }
    }
}
