// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: Arial,"メイリオ", Meiryo, Osaka, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$black: #000000;
$gray: #aeaeae;
$lightgray: #DDDDDD;

// ThemeColor
// header/footer
$theme-header-green: #62B15B;
$theme-footer-middle-green: #C3F09A;
$theme-footer-bottom-green: #62B15B;

// badge
$theme-badge-red: #FF0000;
$theme-badge-yellow: #E2DF88;
$theme-badge-green: #2ABB89;

// accent color (font, button, icon, border)
$theme-accent-black: #000000;
$theme-accent-lightblack: #333;
$theme-accent-darkgray: #444444;
$theme-accent-gray: #3c3c3c;
$theme-accent-lightgray: #CECECE;
$theme-accent-graywhite: #E7E7E7;
$theme-accent-white: #fafafa;
$theme-accent-red: #E40000;
$theme-accent-yellow: #F8CD25;
$theme-accent-lightyellow: #FCDB5B;
$theme-accent-green: #558B6E;
$theme-accent-lightgreen: #60cc2f;

// main (background)
$theme-main-black: #000000;
$theme-main-gray: #AFAFAF;
$theme-main-lightgray: #DDDDDD;
$theme-main-graywhite: #f7f7f7;
$theme-main-white: #fff;
$theme-main-green: #006C67;
$theme-main-navy: #000b3c;

$shadow: rgba(128, 128, 128, 0.5);
$shadow-gray: rgba(128, 128, 128, 0.25);
$modal-back: rgba(0, 0, 0, 0.8);

// bootstrap
$grid-columns: 36;
$grid-gutter-width: 0;
$grid-row-columns: 6;